$gutter: 0.5rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";

// attendance issues
.late-color {
  color: #f44336;
}
.before-color {
  color: #ff9800;
}
.overtime-color {
  color: #4caf50;
}
.missing-color {
  color: #2196f3;
}
.absence-color {
  color: #f44336;
}
.exceed_break-color {
  color: #f44336;
}
.pause_segment-color {
  color: var(--text-color-secondary);
}

.is-required::after {
  content: " *";
  color: #dc3545;
}

.p-tooltip.tooltip-calendar-event {
  max-width: 30rem !important;
  min-width: 20rem !important;
}

.text-thirty-color {
  color: #4ecdc4;
}

.text-secondary-color {
  color: var(--secondary-color);
}

.white-space-pre-line {
  white-space: pre-line;
}

.word-wrap-break-word {
  word-wrap: break-word;
}
